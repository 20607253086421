import React from 'react'
import styled from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'

import Background from '../components/Background'
import Social from '../components/Social'
import { colors, device } from '../styles/config'

import Jobs from '../components/Jobs'

const JobsPage = () => {
  return (
    <>
      <Background openTop>
        <Container>
          <Jobs/>
          <Social/>
        </Container>
      </Background>
    </>

  )
}

const Container = styled.div`
  ${ props => props.theme.wrapper }
  ${ props => props.theme.paddingTop }
  ${ props => props.theme.backgroundTop }

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }
  }

  ${ Row } {
    margin-bottom: 9.375rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > div:first-child {
    margin-top: 0;
  }
`

export default JobsPage
